import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import GradientLine from "../Constant/GradientLine";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    minHeight: 1080,
  },
  texts: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto 40px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "120px auto",
    },
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  button: {
    marginTop: "40px",
    fontWeight: "700",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      borderRadius: "24px",
      padding: "10px 24px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      borderRadius: "28px",
      padding: "12px 28px",
    },
  },
}));

const NavajoPage = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {/* Top */}
      <Container maxWidth="lg">
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Advertise on WhereToMine
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            Advertise on WhereToMine and reach a global community of cryptocurrency miners.
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            color="primary"
            disableElevation
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
          >
            Start Your Campaign
          </Button>
        </div>
      </Container>

      <GradientLine />

      {/* Middle */}
      <Container maxWidth="lg"></Container>
      {/* End */}
      <Container maxWidth="lg"></Container>
    </div>
  );
};

export default NavajoPage;
