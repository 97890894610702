import React from "react";

import Root from "../components/Root";
import SEO from "../components/seo";
import NavajoPage from "../components/Advertise/NavajoPage";

const Advertise = () => {
  return (
    <Root>
      <SEO title={`Advertise on WhereToMine`} 
        description={`If you're a pool operator or a coin developer, this is the perfect place to find information on how to advertise on WhereToMine.`}  /> />
      <NavajoPage />
    </Root>
  );
};

export default Advertise;
